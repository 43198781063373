// import React from "react";
// import ConnectWalletButton from "./ConnectWalletButton";
import React, { useState, useEffect } from "react";
import { connectWallet, disconnectWallet } from "../walletConnect.js";
import { FaCopy, FaSignOutAlt, FaTimes } from "react-icons/fa";
import "./ConnectWalletButton.css";

const Dialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, onClose]);

  return (
    <div
      className="dialog-backdrop"
      onClick={handleBackdropClick}
      role="dialog"
      aria-modal="true"
    >
      <div className="dialog-content">
        <button
          onClick={onClose}
          className="close-icon"
          aria-label="Close dialog"
        >
          <FaTimes />
        </button>
        {children}
      </div>
    </div>
  );
};

export default function IndexNavbar({ wallet, ...props }) {
  const handleCreateAvatar = () => {
    // Replace this URL with your actual avatar creation link
    window.location.href = "https://demo-avatar-link.com";
  };

  const [walletAddress, setWalletAddress] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  useEffect(() => {
    const storedAddress = localStorage.getItem("walletAddress");
    console.log("stroedAddress", storedAddress);
    if (storedAddress) {
      setWalletAddress(storedAddress);
      setIsWalletConnected(true);
    }
  }, []);

  const handleConnectWallet = async () => {
    console.log("Connecting to wallet...");
    setIsConnecting(true);
    const walletData = await connectWallet();
    console.log("walledData value is:", walletData);
    setIsConnecting(false);

    if (walletData === 0) {
      console.log("Wallet connection failed");
      setShowDialog(true);
    } else if (walletData && walletData !== 0) {
      console.log("Wallet connected successfully");
      setWalletAddress(walletData.address);
      localStorage.setItem("walletAddress", walletData.address);
      setIsCopied(false);
      setIsWalletConnected(true);
    } else {
      console.log("Unexpected error");
    }
  };

  console.log("the iswalletconnected value is ", isWalletConnected);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleDisconnectWallet = () => {
    disconnectWallet();
    setWalletAddress("");
    localStorage.removeItem("walletAddress");
    setIsCopied(false);
    setIsWalletConnected(false);
    // Show the "Join the Waitlist" button
  };

  return (
    <div>
      <header className="top_panel top_panel_custom top_panel_custom_20375 top_panel_custom_header-inverse-main without_bg_image">
        <div
          data-elementor-type="cpt_layouts"
          data-elementor-id="20375"
          className="elementor elementor-20375"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-1860c77 elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-height-default elementor-section-height-default sc_fly_static"
            data-id="1860c77"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-extended">
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a96b402 sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="a96b402"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-5c186bd sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                    data-id="5c186bd"
                    data-element_type="widget"
                    data-widget_type="trx_sc_layouts_logo.default"
                  >
                    <div className="elementor-widget-container">
                      <a
                        href="/"
                        className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_252079505"
                      >
                        <img
                          loading="lazy"
                          className="logo_image"
                          src={`./cobox/img/${props.logo}`}
                          alt="Cobox Universe"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-6e783d3 sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="6e783d3"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-c10e1d5 sc_fly_static elementor-widget elementor-widget-trx_sc_button"
                    data-id="c10e1d5"
                    data-element_type="widget"
                    data-widget_type="trx_sc_button.default"
                  >
                    <div className="elementor-widget-container">
                      {isWalletConnected && (
                        <div className="sc_item_button sc_button_wrap">
                          <a
                            href="#"
                            className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                          >
                            <span className="sc_button_text sc_align_center">
                              <span className="sc_button_title">
                                Join the Waitlist
                              </span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <ConnectWalletButton /> */}
                  <div className="flex flex-col items-center mt-10 text-sm">
                    {!walletAddress ? (
                      <button
                        onClick={handleConnectWallet}
                        // className={`bg-white text-black text-sm py-2 px-6 rounded-full hover:bg-blue-600 transition-all shadow-md ${
                        //   isConnecting && "opacity-70 cursor-not-allowed"
                        // }`}
                        className='p-0'
                        disabled={isConnecting}
                      >
                        {/* {isConnecting ? "Connecting..." : "Connect Wallet"} */}
                        <div className="sc_item_button sc_button_wrap">
                          <a
                            href="#"
                            className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                          >
                            <span className="sc_button_text sc_align_center">
                              <span className="sc_button_title">
                                {isConnecting ? "Connecting..." : "Connect Wallet"}
                              </span>
                            </span>
                          </a>
                        </div>
                      </button>
                    ) : (
                      <div className="relative">
                        <div className="absolute bg-white text-black border border-gray-500 rounded-lg shadow-lg z-10">
                          <span className="font-mono">
                            {walletAddress.slice(0, 6)}...
                            {walletAddress.slice(-4)}
                          </span>
                          <button
                            onClick={handleCopyAddress}
                            className={`p-2 rounded-full ${
                              isCopied ? "text-green-500" : "text-black"
                            }`}
                            title="Copy Address"
                          >
                            <FaCopy />
                          </button>
                          <button
                            onClick={handleDisconnectWallet}
                            className="p-2 rounded-full text-black"
                            title="Disconnect Wallet"
                          >
                            <FaSignOutAlt />
                          </button>
                        </div>
                      </div>
                    )}
                    <Dialog
                      isOpen={showDialog}
                      onClose={() => setShowDialog(false)}
                    >
                      <div className="dialog-inner">
                        <h2>Connection Failed</h2>
                        <p>Watch the video below for troubleshooting steps</p>
                        <div className="video-container">
                          <iframe
                            src="https://www.youtube.com/embed/A7sbpFvkEe0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </Dialog>
                  </div>

                  {/* <button onClick={handleCreateAvatar} className="ggg ">
                    <span className="sc_button_text sc_align_center">
                      <span className="sc_button_title">Join the Waitlist</span>
                    </span>
                  </button> */}

                  <button onClick={handleCreateAvatar} className="ms-4 p-0">
                    <div className="elementor-widget-container">
                      <div className="sc_item_button sc_button_wrap">
                        <a
                          href="http://13.60.172.44/"
                          className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                        >
                          <span className="sc_button_text sc_align_center">
                            <span className="sc_button_title">
                              Create Avatar
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2c8440d sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="2c8440d"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-b475eec sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                    data-id="b475eec"
                    data-element_type="widget"
                    data-widget_type="trx_sc_layouts_menu.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="sc_layouts_iconed_text sc_layouts_menu_mobile_button_burger sc_layouts_menu_mobile_button">
                        <a
                          className="sc_layouts_item_link sc_layouts_iconed_text_link"
                          href="#"
                        >
                          <span
                            className={`${props.toggle_clr} sc_layouts_iconed_text_icon trx_addons_icon-menu`}
                          >
                            <i
                              className={`bi bi-list`} 
                              style={{ fontSize: "40px"}}
                            ></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-3960116 elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
            data-id="3960116"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-extended">
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f9e8646 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="f9e8646"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-900c76a sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                    data-id="900c76a"
                    data-element_type="widget"
                    data-widget_type="trx_sc_layouts_logo.default"
                  >
                    <div className="elementor-widget-container">
                      <a
                        href="/"
                        className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1943379073"
                      >
                        <img
                          loading="lazy"
                          className="logo_image"
                          src={`./cobox/img/${props.logo}`}
                          alt="Cobox Universe"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-6a4c880 sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="6a4c880"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-976a99d sc_fly_static elementor-widget elementor-widget-button"
                    data-id="976a99d"
                    data-element_type="widget"
                    data-widget_type="button.default"
                  >
                    <div className="elementor-widget-container">
                      {isWalletConnected && (
                        <div className="sc_item_button sc_button_wrap">
                          <a
                            href="#"
                            className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                          >
                            <span className="sc_button_text sc_align_center">
                              <span className="sc_button_title">
                                Join the Waitlist
                              </span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <ConnectWalletButton /> */}
                  <div className="flex flex-col items-center mt-10 text-sm">
                    {!walletAddress ? (
                      <button
                        onClick={handleConnectWallet}
                        // className={`bg-white text-black text-sm py-2 px-6 rounded-full hover:bg-blue-600 transition-all shadow-md ${
                        //   isConnecting && "opacity-70 cursor-not-allowed"
                        // }`}
                        className="p-0"
                        disabled={isConnecting}
                      >
                        {/* {isConnecting ? "Connecting..." : "Connect Wallet"} */}
                        <div className="sc_item_button sc_button_wrap">
                          <a
                            href="#"
                            className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                          >
                            <span className="sc_button_text sc_align_center">
                              <span className="sc_button_title">
                                {isConnecting ? "Connecting..." : "Connect Wallet"}
                              </span>
                            </span>
                          </a>
                        </div>
                      </button>
                    ) : (
                      <div className="relative">
                        <div className="absolute bg-white text-black border border-gray-500 rounded-lg shadow-lg z-10">
                          <span className="font-mono">
                            {walletAddress.slice(0, 6)}...
                            {walletAddress.slice(-4)}
                          </span>
                          <button
                            onClick={handleCopyAddress}
                            className={`p-2 rounded-full ${
                              isCopied ? "text-green-500" : "text-black"
                            }`}
                            title="Copy Address"
                          >
                            <FaCopy />
                          </button>
                          <button
                            onClick={handleDisconnectWallet}
                            className="p-2 rounded-full text-black"
                            title="Disconnect Wallet"
                          >
                            <FaSignOutAlt />
                          </button>
                        </div>
                      </div>
                    )}
                    <Dialog
                      isOpen={showDialog}
                      onClose={() => setShowDialog(false)}
                    >
                      <div className="dialog-inner">
                        <h2>Connection Failed</h2>
                        <p>Watch the video below for troubleshooting steps</p>
                        <div className="video-container">
                          <iframe
                            src="https://www.youtube.com/embed/A7sbpFvkEe0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </Dialog>
                  </div>
                  {/* <button onClick={handleCreateAvatar} className="ggg">
                    Create Avatar
                  </button> */}
                   <button onClick={handleCreateAvatar} className="ms-4 p-0">
                    <div className="elementor-widget-container">
                      <div className="sc_item_button sc_button_wrap">
                        <a
                          href="http://13.60.172.44/"
                          className={`sc_button sc_button_default sc_button_size_small sc_button_icon_left ${props.btn_class}`}
                        >
                          <span className="sc_button_text sc_align_center">
                            <span className="sc_button_title">
                              Create Avatar
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a56cb2d sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="a56cb2d"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="sc_layouts_item elementor-element elementor-element-cc88146 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                    data-id="cc88146"
                    data-element_type="widget"
                    data-widget_type="trx_sc_layouts_menu.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="sc_layouts_iconed_text sc_layouts_menu_mobile_button_burger sc_layouts_menu_mobile_button without_menu">
                        <a
                          className="sc_layouts_item_link sc_layouts_iconed_text_link"
                          href="#"
                        >
                          <span className="sc_layouts_item_icon sc_layouts_iconed_text_icon trx_addons_icon-menu">
                          <i
                              className={`bi bi-list ${props.btn_class === 'color_style_dark' ? props.btn_class : props.toggle_clr}`} 
                              style={{ fontSize: "40px", marginLeft: "-25px" }}
                            ></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </header>

      <div className="menu_mobile_overlay scheme_dark"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_dark">
        <div className="menu_mobile_inner with_widgets">
          <div className="menu_mobile_header_wrap">
            <a className="sc_layouts_logo" href="/">
              <img
                loading="lazy"
                src="//cobox.co/wp-content/uploads/2022/09/logo-bbig.png"
                alt="Cobox Universe"
              />
            </a>

            <a className="menu_mobile_close menu_button_close" tabindex="0">
              <span className="menu_button_close_text">Close</span>
              <span className="menu_button_close_icon"></span>
            </a>
          </div>
          <div className="menu_mobile_content_wrap content_wrap">
            <div className="menu_mobile_content_wrap_inner">
              <nav
                className="menu_mobile_nav_area"
                itemscope="itemscope"
                itemtype="https://schema.org/SiteNavigationElement"
              >
                <ul id="menu_mobile_1252127373">
                  <li
                    id="menu_mobile-item-22566"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-18861 current_page_item menu-item-22566"
                  >
                    <a href={`/`} aria-current="page">
                      <span>Home</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-19176"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19176"
                  >
                    <a href={`/about`}>
                      <span>About Us</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-18939"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18939"
                  >
                    <a href={`/whitepaper`}>
                      <span>Whitepaper</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-22476"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-22476"
                  >
                    <a href="https://cobox.co/blogs/">
                      <span>Blogs</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-17194"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17194"
                  >
                    <a href={`/contact`}>
                      <span>Contact</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="socials_mobile">
                <a
                  target="_blank"
                  href="https://www.facebook.com/COBOX-109559738464418/"
                  className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                >
                  <span className="social_icon social_icon_facebook-1">
                    <span className="icon-facebook-1"></span>
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/COBOXmetaverse"
                  className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                >
                  <span className="social_icon social_icon_twitter-new">
                    <span className="icon-twitter-new"></span>
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/coboxcommunity"
                  className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                >
                  <span className="social_icon social_icon_telegram">
                    <span className="icon-telegram"></span>
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://instagram.com/coboxmetaverse?igshid=YmMyMTA2M2Y="
                  className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                >
                  <span className="social_icon social_icon_instagram">
                    <span className="icon-instagram"></span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="menu_mobile_widgets_area">
            <div className="widgets_additional_menu_mobile_fullscreen widgets_additional_menu_mobile_fullscreen_wrap widget_area">
              <div className="widgets_additional_menu_mobile_fullscreen_inner widgets_additional_menu_mobile_fullscreen_inner widget_area_inner">
                <aside className="widget_text widget widget_custom_html">
                  <div className="textwidget custom-html-widget">
                    <div className="extra_item">
                      <h6>Have a Project?</h6>
                      <a href="mailto:info@cobox.co">info@cobox.co</a>
                    </div>

                    <div className="extra_item">
                      <h6>Want to Work With Us?</h6>
                      <a href="https://cobox.co/contact/">Send Brief</a>
                    </div>

                    <div className="extra_item">
                      <h6>Want to Read More?</h6>
                      <a href="#">Go to Blog</a>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
