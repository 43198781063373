import React from "react";
import "./CoboxUniverse.css";
import IndexNavbar from "./IndexNavbar";
import Footer from "./Footer";

export default function CoBoxUniverse() {
  return (
    <div class="body_wrap">
      <div class="page_wrap">
        <IndexNavbar
          logo="logo-bbig.png"
          btn_class="color_style_dark"
          toggle_clr="sc_layouts_item_icon"
        />
          <div>
            <h1 class="cobox-text">COBOX UNIVERSE</h1>
          </div>

          <div class="container text-center">
            <div class="row d-flex align-items-center p-3">
              <div class="col-md-6 order-md-1 order-2">
                <img src="/coboximg/cobox_universe/1.jpg" alt="blog2" class="blog-img" />
              </div>
              <div class="col-md-6 order-md-2 order-1">
                <h1 class="create-text">CREATE</h1>
                <p class="create-text">
                  Cobox Universe permits users to design virtual environment
                  games or other interactive experiences and create 3D models
                  where doors are open for users for every action or puzzle to
                  solve.
                </p>
              </div>
            </div>
          </div>

          <div class="container text-center">
            <div class="row d-flex align-items-center p-3">
              <div class="col-md-6 order-md-1 order-1">
                <h1 class="create-text">PLAY</h1>
                <p class="create-text">
                  Cobox Universe allows users to explore different environments,
                  participate in various activities, and even create their
                  content with customized avatars.
                </p>
              </div>
              <div class="col-md-6 order-md-2 order-2">
                <img src="/coboximg/cobox_universe/2.jpg" alt="blog2" class="blog-img" />
              </div>
            </div>
          </div>

          <div class="container text-center">
            <div class="row d-flex align-items-center p-3">
              <div class="col-md-6 order-md-1 order-2">
                <img src="/coboximg/cobox_universe/3.jpg" alt="Earn" class="blog-img" />
              </div>
              <div class="col-md-6 order-md-2 order-1">
                <h1 class="create-text">EARN</h1>
                <p class="create-text">
                  Cobox Universe empowers both creator and player to participate
                  in and benefit from digital economy creativity and engagement
                  while establishing a sustainable and decentralized economic
                  framework where value is transparently exchanged.
                </p>
              </div>
            </div>
          </div>

          <div class="container text-center">
            <div class="row d-flex align-items-center p-3">
              <div class="col-md-6 order-md-1 order-1">
                <h1 class="create-text">MONETIZE</h1>
                <p class="create-text">
                  Cobox Universe involves utilizing various decentralized tools
                  and strategies to generate revenue from our platform, gaming
                  activities, and community participation. Both creators and
                  players can take advantage of NFTs and other Web 3 innovations
                  to create a sustainable and equitable digital economy.
                </p>
              </div>
              <div class="col-md-6 order-md-2 order-2">
                <img src="/coboximg/cobox_universe/4.jpg" alt="Monetize" class="blog-img" />
              </div>
            </div>
          </div>
      

        <Footer />
      </div>
    </div>
  );
}
