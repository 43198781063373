// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: black;
}
.container-fluid {
    padding-left: 5%;
    padding-right: 5%;
}
.cobox-text {
    text-align: center;
    color: white;
    margin-top: 10px;
    font-size: 125px;
    line-height: 1em;
}
.centered-line {
    width: 90%;
    border-top: 4px solid white;
    margin: 0 auto;
}
.container {
    padding: 3% 5%;
}
.blog-img {
    width: 100%;
    max-width: 450px;
    height: auto;
}
.create-text {
    color: white;
    text-align: left;
    padding-left: 5%;
}
/* Responsive adjustments */
@media (max-width: 992px) {
    .cobox-text {
        font-size: 60px;
    }
    .container-fluid {
        padding-left: 3%;
        padding-right: 3%;
    }
}
@media (max-width: 768px) {
    .cobox-text {
        font-size: 50px;
    }
    .centered-line {
        width: 80%;
    }
    .container {
        padding: 2%;
    }
}
@media (max-width: 576px) {
    .cobox-text {
        font-size: 40px;
    }
    .container-fluid {
        padding-left: 2%;
        padding-right: 2%;
    }
    .centered-line {
        width: 70%;
    }
    .blog-img {
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/CoboxUniverse.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,2BAA2B;IAC3B,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;AACA,2BAA2B;AAC3B;IACI;QACI,eAAe;IACnB;IACA;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;IACd;IACA;QACI,WAAW;IACf;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,gBAAgB;QAChB,iBAAiB;IACrB;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":["body {\r\n    background-color: black;\r\n}\r\n.container-fluid {\r\n    padding-left: 5%;\r\n    padding-right: 5%;\r\n}\r\n.cobox-text {\r\n    text-align: center;\r\n    color: white;\r\n    margin-top: 10px;\r\n    font-size: 125px;\r\n    line-height: 1em;\r\n}\r\n.centered-line {\r\n    width: 90%;\r\n    border-top: 4px solid white;\r\n    margin: 0 auto;\r\n}\r\n.container {\r\n    padding: 3% 5%;\r\n}\r\n.blog-img {\r\n    width: 100%;\r\n    max-width: 450px;\r\n    height: auto;\r\n}\r\n.create-text {\r\n    color: white;\r\n    text-align: left;\r\n    padding-left: 5%;\r\n}\r\n/* Responsive adjustments */\r\n@media (max-width: 992px) {\r\n    .cobox-text {\r\n        font-size: 60px;\r\n    }\r\n    .container-fluid {\r\n        padding-left: 3%;\r\n        padding-right: 3%;\r\n    }\r\n}\r\n@media (max-width: 768px) {\r\n    .cobox-text {\r\n        font-size: 50px;\r\n    }\r\n    .centered-line {\r\n        width: 80%;\r\n    }\r\n    .container {\r\n        padding: 2%;\r\n    }\r\n}\r\n@media (max-width: 576px) {\r\n    .cobox-text {\r\n        font-size: 40px;\r\n    }\r\n    .container-fluid {\r\n        padding-left: 2%;\r\n        padding-right: 2%;\r\n    }\r\n    .centered-line {\r\n        width: 70%;\r\n    }\r\n    .blog-img {\r\n        width: 80%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
