import React from 'react'

export default function Footer() {
  return (
<>
    <footer className="footer_wrap footer_custom footer_custom_20382 footer_custom_footer-default-single">
        <div data-elementor-type="cpt_layouts" data-elementor-id="20382" className="elementor elementor-20382">
            <section className="elementor-section elementor-top-section elementor-element elementor-element-add19de scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="add19de" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bad6098 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="bad6098" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-455c22a sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="455c22a" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ba820dc sc_layouts_row sc_layouts_row_type_compact scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="ba820dc" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f0381a7 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="f0381a7" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-dacc3f2 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="dacc3f2" data-element_type="widget"
                            data-widget_type="trx_sc_title.default">
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                <h6
                                    className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">Newsletter Signup</span>
                                </h6>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-607ce49 sc_fly_static elementor-widget elementor-widget-shortcode"
                            data-id="607ce49" data-element_type="widget"
                            data-widget_type="shortcode.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-shortcode">
                                <form action="" method="post" style={{"display": "flex","alignItems": "center"}}>
                                    <i className="bi bi-envelope" style={{"marginRight": "8px"}}></i>
                                    <input type="text" name="" id="" placeholder="Enter Your Email Address..." />
                                    <button style={{"marginLeft":"10px","fontSize": "25px","width": "30px","height": "30px","padding": "0","borderRadius": "50%","display": "flex","alignItems": "center","justifyContent": "center"}}>
                                        <i className="bi bi-arrow-right"></i></button>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-872a7e7 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="872a7e7" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-2100d38 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="2100d38" data-element_type="widget"
                            data-widget_type="trx_sc_title.default">
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                <h6
                                    className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">Socials</span>
                                </h6>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-8c3adc5 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="8c3adc5" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-25b1831 sc_fly_static elementor-widget elementor-widget-trx_sc_socials"
                            data-id="25b1831" data-element_type="widget"
                            data-widget_type="trx_sc_socials.default">
                            <div className="elementor-widget-container">
                                <div className="sc_socials sc_socials_names sc_align_left">
                                <div className="socials_wrap sc_item_content"><a target="_blank"
                                    href="https://www.facebook.com/COBOX-109559738464418/"
                                    className="social_item social_item_style_icons sc_icon_type_icons social_item_type_names"><span
                                    className="social_name social_facebook-1">Facebook</span></a><a
                                    target="_blank" href="https://twitter.com/COBOXmetaverse"
                                    className="social_item social_item_style_icons sc_icon_type_icons social_item_type_names"><span
                                    className="social_name social_twitter-new">Twitter</span></a><a
                                    target="_blank" href="https://t.me/coboxcommunity"
                                    className="social_item social_item_style_icons sc_icon_type_icons social_item_type_names"><span
                                    className="social_name social_telegram">Telegram</span></a><a
                                    target="_blank"
                                    href="https://instagram.com/coboxmetaverse?igshid=YmMyMTA2M2Y="
                                    className="social_item social_item_style_icons sc_icon_type_icons social_item_type_names"><span
                                    className="social_name social_instagram">Instagram</span></a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0320615 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="0320615" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-6e5b0e9 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="6e5b0e9" data-element_type="widget"
                            data-widget_type="trx_sc_title.default">
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                <h6
                                    className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">Menu</span>
                                </h6>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-ca83f54 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="ca83f54" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-fa0aa4b sc_fly_static elementor-widget elementor-widget-trx_widget_custom_links"
                            data-id="fa0aa4b" data-element_type="widget"
                            data-widget_type="trx_widget_custom_links.default">
                            <div className="elementor-widget-container">
                                <div className="widget_area sc_widget_custom_links">
                                <aside className="widget widget_custom_links">
                                    <ul className="custom_links_list">
                                        <li className="custom_links_list_item">
                                            <a className="custom_links_list_item_link" href={`/`}>
                                            <span className="custom_links_list_item_title">Home</span></a>
                                        </li>
                                        <li className="custom_links_list_item"><a
                                            className="custom_links_list_item_link"
                                            href={`/about`}><span
                                            className="custom_links_list_item_title">About
                                            Us</span></a>
                                        </li>
                                        <li className="custom_links_list_item"><a
                                            className="custom_links_list_item_link"
                                            href={`/whitepaper`}><span
                                            className="custom_links_list_item_title">Whitepaper</span></a>
                                        </li>
                                        <li className="custom_links_list_item"><a
                                            className="custom_links_list_item_link"
                                            href="#"><span
                                            className="custom_links_list_item_title">Blogs</span></a>
                                        </li>
                                        <li className="custom_links_list_item"><a
                                            className="custom_links_list_item_link"
                                            href={`/contact`}><span
                                            className="custom_links_list_item_title">Contact</span></a>
                                        </li>
                                    </ul>
                                </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ac0556d sc_layouts_row sc_layouts_row_type_compact scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="ac0556d" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c9522e1 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="c9522e1" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-04fb58c sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="04fb58c" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-36538bf sc_height_medium sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="36538bf" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-a53f692 elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider"
                            data-id="a53f692" data-element_type="widget" data-widget_type="divider.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-divider">
                                <span className="elementor-divider-separator">
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-05cc2f2 sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="05cc2f2" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-a9da6a1 sc_fly_static elementor-widget elementor-widget-text-editor"
                            data-id="a9da6a1" data-element_type="widget"
                            data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                                <p>Copyright © 2024 COBOX Metaverse. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-4ebb21c sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="4ebb21c" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <div className="sc_layouts_item elementor-element elementor-element-1f9d4ff sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="1f9d4ff" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-922f476 elementor-section-full_width scheme_dark elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="922f476" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61dabb2 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="61dabb2" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="sc_layouts_item elementor-element elementor-element-7771650 elementor-absolute sc_fly_static elementor-widget elementor-widget-image"
                            data-id="7771650" data-element_type="widget"
                            data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                            data-widget_type="image.default">
                            <div className="elementor-widget-container">
                                <img loading="lazy" width="1920" height="424"
                                src="https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer.png"
                                className="attachment-full size-full wp-image-21381" alt=""
                                srcset="https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer.png 1920w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-300x66.png 300w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-1024x226.png 1024w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-768x170.png 768w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-1536x339.png 1536w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-370x82.png 370w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-840x186.png 840w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-410x91.png 410w, https://cobox.co/wp-content/uploads/2022/09/h1-vector-footer-600x133.png 600w"
                                sizes="(max-width: 1920px) 100vw, 1920px" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    </footer>

    <div
    class="trx_addons_mouse_helper trx_addons_mouse_helper_base trx_addons_mouse_helper_style_default trx_addons_mouse_helper_smooth trx_addons_mouse_helper_permanent trx_addons_mouse_helper_centered">
    </div>
</>
  )
}
