import IndexNavbar from "./component/IndexNavbar";
import About from "./component/About";
import Contact from "./component/Contact";
import Whitepaper from "./component/Whitepaper";
import Home from "./component/Home";
import { Component } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CoboxUniverse from "./component/CoboxUniverse";

function App() {
  const router=createBrowserRouter([
    {
      path:"/about",
      element:<About />
    },
    {
      path:"/contact",
      element:<Contact />
    },
    {
      path:"/whitepaper",
      element:<Whitepaper />
    },
    {
      path:"/",
      element:<Home />
    },
    {
      path: "/cobox-universe",
      element: <CoboxUniverse/>
    }
  ]);

  return <RouterProvider router={router} />

  ;
}

export default App;
