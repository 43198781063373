import React from "react";
import IndexNavbar from "./IndexNavbar";
import Footer from "./Footer";
import { useEffect, useRef } from "react";

export default function home() {
  return (
    <div class="body_wrap">
      <div class="page_wrap">
        <IndexNavbar
          logo="logo-bbig.png"
          btn_class="color_style_dark"
          toggle_clr="sc_layouts_item_icon"
        />

        <div className="page_content_wrap">
          <div className="content_wrap_fullscreen">
            <div className="content">
              <a
                id="content_skip_link_anchor"
                className="gamic_skip_link_anchor"
                href="#"
              ></a>

              <article
                id="post-18861"
                className="post_item_single post_type_page post-18861 page type-page status-publish hentry"
              >
                <div className="post_content entry-content overflow-x-hidden">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="18861"
                    className="elementor elementor-18861"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-12b44d4a elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="12b44d4a"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-58472dd4 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="58472dd4"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4a27f95d sc_height_medium sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="4a27f95d"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2aa2c1a6 sc_height_tiny sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="2aa2c1a6"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-332c7445 elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider"
                              data-id="332c7445"
                              data-element_type="widget"
                              data-widget_type="divider.default"
                            >
                              {/* <div className="elementor-widget-container">
                                <div className="elementor-divider">
                                  <span className="elementor-divider-separator"></span>
                                </div>
                              </div> */}
                            </div>
                            <div
                              className="elementor-element elementor-element-76254e7 sc_height_large sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="76254e7"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2da7a4e6 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="2da7a4e6"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      Unleash your imagination
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2d605992 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="2d605992"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      Boundless Creation With NO COST
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-795d0384 sc_height_large sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="795d0384"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-3d206ec sc_height_large sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="3d206ec"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-00728dc elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="00728dc"
                      data-element_type="section"
                      id="content"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-44ec491 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="44ec491"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-735c998 sc_fly_static elementor-widget elementor-widget-video"
                              data-id="735c998"
                              data-element_type="widget"
                              data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","mute":"yes","loop":"yes"}'
                              data-widget_type="video.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="e-hosted-video elementor-wrapper elementor-open-inline">
                                  <video
                                    className="elementor-video"
                                    src="/V2.mp4"
                                    autoplay=""
                                    loop=""
                                    muted="muted"
                                    playsinline=""
                                    controlsList="nodownload"
                                  ></video>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="trx_addons_has_bg_text elementor-section elementor-top-section elementor-element elementor-element-c1821c5 elementor-section-full_width elementor-section-height-min-height elementor-section-stretched elementor-section-height-default elementor-section-items-middle sc_fly_static"
                      data-bg-text='{"bg_text":"Create \nPlay \nEarn \nMonetize","bg_text_effect":"slide","bg_text_overlay":{"url":"","id":"","size":""},"bg_text_left":{"unit":"%","size":"","sizes":[]},"bg_text_top":{"unit":"px","size":0,"sizes":[]},"bg_text_z_index":{"unit":"px","size":"0","sizes":[]},"bg_text_marquee":{"unit":"px","size":0.5,"sizes":[]},"bg_text_marquee_hover":"","bg_text_marquee_margin":{"unit":"px","size":"50","sizes":[]},"bg_text_reverse":0,"bg_text_accelerate":0,"bg_text_delimiter_icon":"","bg_text_delimiter_image":"","bg_text_delimiter_svg":"","bg_text_delimiter_rotation":0}'
                      data-id="c1821c5"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched"}'
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-461fdc5 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="461fdc5"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap"></div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-0b68f57 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="0b68f57"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ca6f91a sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="ca6f91a"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-898e009 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="898e009"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-052980a elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="052980a"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-72cb765 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="72cb765"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4ef2b87 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="4ef2b87"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6bbdcca sc_inner_width_1_2 sc-mobile_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="6bbdcca"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-2060ae0 sc_height_huge sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="2060ae0"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-04fd169 sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="04fd169"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-85e3a74 animation_type_block sc_fly_static  elementor-widget elementor-widget-trx_sc_title"
                              data-id="85e3a74"
                              data-element_type="widget"
                              data-settings={{ _animation: "gamicFadeinup" }}
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      NO COST CREATION FOR CREATORS
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-39a0857 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="39a0857"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a2c0d23 animation_type_block sc_fly_static  elementor-widget elementor-widget-text-editor"
                              data-id="a2c0d23"
                              data-element_type="widget"
                              data-settings='{"_animation":"gamic-fadeinup"}'
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                <p style={{ color: "#fff" }}>
                                  Unlock your potential with our No Cost
                                  Creation for Creators program. We provide a
                                  platform for creators to bring their ideas to
                                  life without the burden of upfront expenses.
                                  Focus on your creativity while we handle the
                                  rest. From resources to support, we ensure
                                  your journey is smooth and cost-free,
                                  empowering you to innovate and succeed. Join
                                  us today and transform your vision into
                                  reality with zero financial barriers.
                                </p>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5b46af8 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="5b46af8"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-8037a18 sc_height_huge sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="8037a18"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-21b63d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="21b63d0"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aa30f67 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="aa30f67"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-a6b9b45 animation_type_block sc_fly_static  elementor-widget elementor-widget-trx_sc_title"
                              data-id="a6b9b45"
                              data-element_type="widget"
                              data-settings='{"_animation":"gamic-fadeinup"}'
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      OUR PRODUCT
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-cb80099 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="cb80099"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5bd218b sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="5bd218b"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-0769a72 no-title sc_fly_static elementor-widget elementor-widget-image"
                              data-id="0769a72"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <img
                                  fetchpriority="high"
                                  decoding="async"
                                  width="1080"
                                  height="1920"
                                  src="/2.png"
                                  className="attachment-full size-full wp-image-22175"
                                  alt=""
                                  srcset="/2.png"
                                  sizes="(max-width: 1080px) 100vw, 1080px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b0ae04c sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="b0ae04c"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-afaa099 sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="afaa099"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5567889 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="5567889"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <div className="sc_item_descr sc_title_descr">
                                    <h2 style={{ color: "#fff" }}>
                                      AI YODHA POWER
                                    </h2>
                                    <p style={{ color: "#fff" }}>
                                      In the ever-evolving landscape of the
                                      Cobox Universe, we are proud to introduce
                                      Yodha, an advanced Artificial Intelligence
                                      designed to empower creators, game
                                      developers, and digital artists.
                                    </p>
                                    <p style={{ color: "#fff" }}>
                                      Yodha embodies the spirit of a warrior,
                                      assisting users in navigating the
                                      complexities of virtual creation,
                                      enhancing productivity, and refining
                                      business and marketing strategies.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-699176d sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="699176d"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-8e8ae4f elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="8e8ae4f"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-89c6abf sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="89c6abf"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-e43cf9c sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="e43cf9c"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-ca9d966 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="ca9d966"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      No Code Studio
                                    </span>
                                  </h1>
                                  <div className="sc_item_descr sc_title_descr">
                                    <p style={{ color: "#fff" }}>
                                      No Code virtual studio empowers creators
                                      to unleash their creativity without the
                                      limitations of physical reality. It offers
                                      robust streaming capabilities to support
                                      creators financially, along with tools
                                      designed to enhance visibility and
                                      audience growth.This platform provides a
                                      canvas for artists, designers, and
                                      innovators to build immersive
                                      experiences,virtual environments, and
                                      interactive content, all without needing
                                      programming skills. Creators can craft
                                      unique digital worlds, connect with
                                      audiences in real time, and monetize their
                                      creations through various channels within
                                      the virtual ecosystem.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-c820a5b sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="c820a5b"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-33bb3c1 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="33bb3c1"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-e7dbac1 no-title sc_fly_static elementor-widget elementor-widget-image"
                              data-id="e7dbac1"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container elementor-img">
                                <img
                                  decoding="async"
                                  width="2560"
                                  height="1463"
                                  src="/3.jpg"
                                  className="attachment-full size-full wp-image-22343"
                                  alt=""
                                  srcset="/3.jpg"
                                  sizes="(max-width: 2560px) 100vw, 2560px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-3a2e606 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="3a2e606"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7a44012 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="7a44012"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-e1c271f no-title sc_fly_static elementor-widget elementor-widget-image"
                              data-id="e1c271f"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <img
                                  decoding="async"
                                  width="578"
                                  height="539"
                                  src="/4.jpg"
                                  className="attachment-full size-full wp-image-22200"
                                  alt=""
                                  srcset="/4.jpg"
                                  sizes="(max-width: 600px) 100vw, 578px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0d83501 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="0d83501"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-78d1b07 sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="78d1b07"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5f87f2d sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="5f87f2d"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      COBOX UNIVERSE
                                    </span>
                                  </h1>
                                  <div className="sc_item_descr sc_title_descr">
                                    <p style={{ color: "#fff" }}>
                                      Serves as the underlying universe where
                                      users can develop interconnected
                                      metaverses with customizable
                                      architectures, logic, tokens and gateways.
                                      Build anything from customizable
                                      avatar-based social spaces to virtual
                                      stores for businesses to augmented reality
                                      streams and events.
                                    </p>
                                  </div>
                                  <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button">
                                    <a
                                      href="/cobox-universe"
                                      className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"
                                    >
                                      <span className="sc_button_text">
                                        <span className="sc_button_title">
                                          Read More
                                        </span>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5a80977 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="5a80977"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-6a2a3df9 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="6a2a3df9"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="elementor-background-overlay"></div>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-728e61fe sc_layouts_column_align_center sc_layouts_column sc-mobile_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="728e61fe"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                              className="elementor-section-with-custom-width elementor-section elementor-inner-section elementor-element elementor-element-be72070 scheme_dark elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default sc_fly_static"
                              data-id="be72070"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-no">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-35620fc sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                                  data-id="35620fc"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7e154f7 sc_fly_static elementor-widget elementor-widget-video"
                                      data-id="7e154f7"
                                      data-element_type="widget"
                                      data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","mute":"yes","loop":"yes"}'
                                      data-widget_type="video.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="e-hosted-video elementor-wrapper elementor-open-inline">
                                          <video
                                            className="elementor-video"
                                            src="/V1.mp4"
                                            autoplay=""
                                            loop=""
                                            muted="muted"
                                            playsinline=""
                                            controlsList="nodownload"
                                          ></video>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <div
                              className="elementor-element elementor-element-220ef1c sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="220ef1c"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="trx_addons_has_bg_text elementor-section elementor-top-section elementor-element elementor-element-f5b2946 elementor-section-full_width elementor-section-height-min-height elementor-section-stretched elementor-section-content-middle elementor-section-height-default elementor-section-items-middle sc_fly_static"
                      data-bg-text='{"bg_text":"Our games mix the best","bg_text_effect":"slide","bg_text_overlay":{"url":"","id":"","size":""},"bg_text_left":{"unit":"%","size":"","sizes":[]},"bg_text_top":{"unit":"px","size":0,"sizes":[]},"bg_text_z_index":{"unit":"px","size":"0","sizes":[]},"bg_text_marquee":{"unit":"px","size":0.5,"sizes":[]},"bg_text_marquee_hover":"","bg_text_marquee_margin":{"unit":"px","size":"50","sizes":[]},"bg_text_reverse":0,"bg_text_accelerate":0,"bg_text_delimiter_icon":"","bg_text_delimiter_image":"","bg_text_delimiter_svg":"","bg_text_delimiter_rotation":0}'
                      data-id="f5b2946"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched"}'
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8584290 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="8584290"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap"></div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-6cc163e elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="6cc163e"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-adf3d3b sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="adf3d3b"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-ccea625 animation_type_block sc_fly_static  elementor-widget elementor-widget-trx_sc_title"
                              data-id="ccea625"
                              data-element_type="widget"
                              data-settings='{"_animation":"gamic-fadeinup"}'
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
                                    coming Projets on Our
                                  </span>
                                  <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      Cobox universe
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-eb8b952 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                              data-id="eb8b952"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2df6f36 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                                  data-id="2df6f36"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-a740018 sc_fly_static elementor-widget elementor-widget-image"
                                      data-id="a740018"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          loading="lazy"
                                          decoding="async"
                                          width="1024"
                                          height="279"
                                          src="https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-1024x279.jpg"
                                          className="attachment-large size-large wp-image-22400"
                                          alt=""
                                          srcset="https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-1024x279.jpg 1024w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-300x82.jpg 300w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-768x209.jpg 768w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-370x101.jpg 370w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-840x229.jpg 840w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-410x112.jpg 410w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1-600x163.jpg 600w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_2a27d9c1.jpg 1160w"
                                          sizes="(max-width: 1024px) 100vw, 1024px"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-5774db5 sc_fly_static elementor-widget elementor-widget-heading"
                                      data-id="5774db5"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2
                                          className="elementor-heading-title elementor-size-default"
                                          style={{ color: "#fff" }}
                                        >
                                          Coboverse
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-624cb8f sc_fly_static elementor-widget elementor-widget-image"
                                      data-id="624cb8f"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          loading="lazy"
                                          decoding="async"
                                          width="1024"
                                          height="279"
                                          src="https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-1024x279.jpg"
                                          className="attachment-large size-large wp-image-22399"
                                          alt=""
                                          srcset="https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-1024x279.jpg 1024w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-300x82.jpg 300w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-768x209.jpg 768w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-370x101.jpg 370w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-840x229.jpg 840w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-410x112.jpg 410w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e-600x163.jpg 600w, https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-12.02.44_24c62b0e.jpg 1160w"
                                          sizes="(max-width: 1024px) 100vw, 1024px"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7edf420 sc_fly_static elementor-widget elementor-widget-heading"
                                      data-id="7edf420"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2
                                          className="elementor-heading-title elementor-size-default"
                                          style={{ color: "#fff" }}
                                        >
                                          FANVERSE
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-253cd40 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="253cd40"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ab6c220 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="ab6c220"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-92bd903 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="92bd903"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2535a63 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="2535a63"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      Cobox Blogs
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-64f283a sc_height_small sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="64f283a"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-386efe7 animation_type_sequental sc_style_default sc_fly_static  elementor-widget elementor-widget-trx_sc_blogger"
                              data-id="386efe7"
                              data-element_type="widget"
                              data-settings='{"_animation":"gamic-fadeinup","_animation_delay":100}'
                              data-widget_type="trx_sc_blogger.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_blogger sc_blogger_default sc_blogger_default_classic_simple sc_item_filters_tabs_none alignnone">
                                  <div className="sc_blogger_columns_wrap sc_item_columns sc_item_posts_container trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
                                    <div className="trx_addons_column-1_3 trx_addons_column-1_3-tablet">
                                      <div
                                        data-post-id="2597"
                                        data-item-number="1"
                                        className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic_simple sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_on_plate sc_blogger_item_image_position_top post-2597 post type-post status-publish format-standard has-post-thumbnail hentry category-metaverse-games"
                                      >
                                        <div className="sc_blogger_item_body">
                                          <div
                                            className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured post_featured_bg"
                                            data-ratio="1:1"
                                          >
                                            <span className="post_thumb post_thumb_bg bg_in gamic_inline_1693199707"></span>
                                            <div className="mask"></div>
                                            <a
                                              href="https://cobox.co/cobox-metaverse-virtual-world-another-you/"
                                              aria-hidden="true"
                                              className="link"
                                            ></a>
                                          </div>
                                          <div className="sc_blogger_item_content entry-content">
                                            <div className="post_meta sc_blogger_item_meta post_meta">
                                              <span className="post_meta_item post_categories cat_sep">
                                                <a
                                                  href="https://cobox.co/category/metaverse-games/"
                                                  rel="category tag"
                                                >
                                                  Metaverse Games
                                                </a>
                                              </span>
                                              <span className="post_meta_item post_date">
                                                <a href="https://cobox.co/cobox-metaverse-virtual-world-another-you/">
                                                  July 18, 2024
                                                </a>
                                              </span>
                                            </div>
                                            <h5
                                              className="sc_blogger_item_title entry-title"
                                              data-item-number="1"
                                            >
                                              <a
                                                href="https://cobox.co/cobox-metaverse-virtual-world-another-you/"
                                                rel="bookmark"
                                              >
                                                Cobox Metaverse : Virtual world
                                                Another You
                                              </a>
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="trx_addons_column-1_3 trx_addons_column-1_3-tablet">
                                      <div
                                        data-post-id="2599"
                                        data-item-number="2"
                                        className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic_simple sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_on_plate sc_blogger_item_image_position_top post-2599 post type-post status-publish format-standard has-post-thumbnail hentry category-metaverse-games"
                                      >
                                        <div className="sc_blogger_item_body">
                                          <div
                                            className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured post_featured_bg"
                                            data-ratio="1:1"
                                          >
                                            <span className="post_thumb post_thumb_bg bg_in gamic_inline_1207250162"></span>
                                            <div className="mask"></div>
                                            <a
                                              href="https://cobox.co/cobox-metaverse-by-the-help-of-artificial-intelligence-to-reach-every-human-by-2027/"
                                              aria-hidden="true"
                                              className="link"
                                            ></a>
                                          </div>
                                          <div className="sc_blogger_item_content entry-content">
                                            <div className="post_meta sc_blogger_item_meta post_meta">
                                              <span className="post_meta_item post_categories cat_sep">
                                                <a
                                                  href="https://cobox.co/category/metaverse-games/"
                                                  rel="category tag"
                                                >
                                                  Metaverse Games
                                                </a>
                                              </span>
                                              <span className="post_meta_item post_date">
                                                <a href="https://cobox.co/cobox-metaverse-by-the-help-of-artificial-intelligence-to-reach-every-human-by-2027/">
                                                  July 14, 2024
                                                </a>
                                              </span>
                                            </div>
                                            <h5
                                              className="sc_blogger_item_title entry-title"
                                              data-item-number="2"
                                            >
                                              <a
                                                href="https://cobox.co/cobox-metaverse-by-the-help-of-artificial-intelligence-to-reach-every-human-by-2027/"
                                                rel="bookmark"
                                              >
                                                Cobox Metaverse With the Help of
                                                Artificial Intelligence to Reach
                                                Every Human By 2027
                                              </a>
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="trx_addons_column-1_3 trx_addons_column-1_3-tablet">
                                      <div
                                        data-post-id="2595"
                                        data-item-number="3"
                                        className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic_simple sc_blogger_item_odd sc_blogger_item_align_none post_format_audio sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_on_plate sc_blogger_item_image_position_top post-2595 post type-post status-publish format-audio has-post-thumbnail hentry category-metaverse-games post_format-post-format-audio"
                                      >
                                        <div className="sc_blogger_item_body">
                                          <div
                                            className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured post_featured_bg"
                                            data-ratio="1:1"
                                          >
                                            <span className="post_thumb post_thumb_bg bg_in gamic_inline_1074608115"></span>
                                            <div className="mask"></div>
                                            <a
                                              href="https://cobox.co/cobox-metaverse/"
                                              aria-hidden="true"
                                              className="link"
                                            ></a>
                                          </div>
                                          <div className="sc_blogger_item_content entry-content">
                                            <div className="post_meta sc_blogger_item_meta post_meta">
                                              <span className="post_meta_item post_categories cat_sep">
                                                <a
                                                  href="https://cobox.co/category/metaverse-games/"
                                                  rel="category tag"
                                                >
                                                  Metaverse Games
                                                </a>
                                              </span>
                                              <span className="post_meta_item post_date">
                                                <a href="https://cobox.co/cobox-metaverse/">
                                                  June 14, 2024
                                                </a>
                                              </span>
                                            </div>
                                            <h5
                                              className="sc_blogger_item_title entry-title"
                                              data-item-number="3"
                                            >
                                              <a
                                                href="https://cobox.co/cobox-metaverse/"
                                                rel="bookmark"
                                              >
                                                COBOX METAVERSE &#8211; Another
                                                World, Another you
                                              </a>
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-df08b52 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="df08b52"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-8c81bd6 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="8c81bd6"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-71e8b33 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="71e8b33"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4e7f4b6 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="4e7f4b6"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-7d44bdd elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="7d44bdd"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b55e8a8 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="b55e8a8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-6ea90f4 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="6ea90f4"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                  <h2 className="sc_item_title sc_title_title sc_item_title_style_default">
                                    <span
                                      className="sc_item_title_text"
                                      style={{ color: "#fff" }}
                                    >
                                      Subscribe for the exclusive updates!
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-80ebe0b sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="80ebe0b"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-8e1f0ec sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="8e1f0ec"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-ce81dd8 sc_fly_static elementor-widget elementor-widget-shortcode"
                              data-id="ce81dd8"
                              data-element_type="widget"
                              data-widget_type="shortcode.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-shortcode">
                                  <form
                                    action=""
                                    method="post"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className="bi bi-envelope"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    <input
                                      type="text"
                                      name=""
                                      id=""
                                      placeholder="Enter Your Email Address..."
                                    />
                                    <button
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "25px",
                                        width: "30px",
                                        height: "30px",
                                        padding: "0",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="bi bi-arrow-right"></i>
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-2a7a806 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="2a7a806"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-013bade sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="013bade"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-fb3f73a sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="fb3f73a"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-9d52c09 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="9d52c09"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-custom">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-37b860e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="37b860e"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-d88f6ee sc_fly_static elementor-widget elementor-widget-image"
                              data-id="d88f6ee"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <img
                                  loading="lazy"
                                  decoding="async"
                                  width="1600"
                                  height="341"
                                  src="https://cobox.co/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-18-at-13.29.13_d0f6f53d.jpg"
                                  className="attachment-full size-full wp-image-22504"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
