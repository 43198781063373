import React from 'react'
import './Whitepaper.css'
import IndexNavbar from './IndexNavbar'
import Footer from './Footer'

export default function Whitepaper() {
  return (
    <div class="non_index">
    <IndexNavbar logo="logo_clr.png" btn_class="color_style_light" toggle_clr="sc_layouts_item_icon_light" />

    <div className="content-container">
        <a href='https://cobox.gitbook.io/cobox-universe-whitepaper' className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left" target='_blank'><b>DOWNLOAD COBOX WHITEPAPER</b></a>
    </div>

    <div className="container text-center">
        <div className="row d-flex flex-column flex-md-row">
            <div className="col-md-5 text-start order-2 order-md-1">
                <p className="text-height">Cobox White Paper:</p>
                <h1 className="heading-up">THE BLUEPRINT OF THE VIRTUAL COSMOS</h1>
                <p className="text-height">
                    Welcome to the Cobox Universe, where the limits of digital 
                    creation and interaction are constantly pushed to new heights.
                    Our white paper provides an in-depth look into the foundation 
                    and future of Cobox, outlining the innovative solutions we
                    offer to creators, developers, and businesses in the virtual realm.
                </p>
            </div>
            <div className="col-md-7 text-end order-1 order-md-2">
                <a href="#">
                    <img src="/w1.jpg" alt="Whitepaper Image" className="img-fluid" />
                </a>
            </div>
        </div>
    </div>

    <div className="img-container">
        <img src="/w2.jpg" alt="Whitepaper Image" />
        <div className="text-overlay">
           <h1>INTRODUCTION</h1>
           <p>The Cobox Universe is a pioneering digital ecosystem designed to empower creators, 
            entrepreneurs, and visionaries. With interconnected planets that offer diverse themes 
            and endless opportunities, our platform is built on the principles of flexibility, 
            interoperability, and creativity.</p> 
        </div>
    </div>

    <div className="container text-center">
        <div className="row d-flex flex-column-reverse flex-md-row">
            <div className="col-md-6">
                <img src="/w3.jpg" alt="Key Components Image" className="component-img img-fluid" />
            </div>
            <div className="col-md-6 text-start">
                <h1><b>KEY COMPONENTS</b></h1>
                <p className="key-components">
                    ● <b>Cobox Studio</b>: A state-of-the-art no-code tool with a drag-and-drop interface, making the creation of high-quality games and immersive virtual environments accessible to everyone.<br />
                    ● <b>Cobox Land</b>: The virtual real estate upon which users build their dreams, offering complete ownership and customization of virtual spaces.<br />
                    ● <b>Cobox Token</b>: The primary currency within our ecosystem, facilitating transactions, rewarding participation, and encouraging creativity.
                </p>
                <h1><b>ECONOMIC ECOSYSTEM</b></h1>
                <p className="ECONOMIC-text">
                    Cobox Land and Cobox Token form the backbone of our economic model, facilitating land sales, leasing, and the development of commercial ventures. This model empowers users to derive tangible value from their virtual investments, fostering a thriving community of creators, entrepreneurs, and investors.
                </p>
                <h1><b>INNOVATIVE SOLUTIONS</b></h1>
                <p className="ECONOMIC-text">
                    Our platform addresses key challenges in the digital landscape, such as the complexity of game development, high costs of creating high-quality content, and the need for effective showcasing and marketing. With Cobox Studio, we provide a comprehensive suite of tools for virtual world building, live streaming, and content management, making it easier for creators to realize and monetize their visions.
                </p>
            </div>
        </div>
    </div>
            <div className="container text-center" style={{"paddingTop": "50px"}}>
                
                <div className="row">
                  <div className="col-lg-8 col-md-12 text-lg-start text-center">
                    <h1 className="future"><b>FUTURE VISION</b></h1>
                    <p className="future-text">
                        As the Cobox Universe grows, we plan to enhance our staking mechanisms, 
                        integrate with third-party platforms, and introduce new ways for users 
                        to earn and spend tokens within the ecosystem. Our commitment to 
                        sustainability ensures that growth is managed in a way that preserves
                         the integrity of the virtual environment.
                    </p>
                    <h1 className="conclusion"><b>CONCLUSION</b></h1>
                    <p className="conclusion-text">
                        The Cobox White Paper is more than just a document; it is a blueprint
                         for the future of digital innovation and community building. We invite 
                         you to explore the possibilities, engage with our vibrant ecosystem,
                          and contribute to the ever-evolving Cobox Universe. For a detailed 
                          overview of our platform, principles, and future plans.
                    </p>
                  </div>
                  <div className="col-lg-4 d-none d-lg-block">
                  <img src="/w4.jpg" alt="Key Components Image" className="component-img img-fluid" />
                  </div>
                </div>
            </div>
            
        <Footer />
    </div>
  )
}
