import React from 'react'
import IndexNavbar from './IndexNavbar'
import Footer from './Footer'
import './Contact.css'

export default function contact() {
  return (
    <div class="non_index">
    <IndexNavbar logo="logo_clr.png" btn_class="color_style_light" toggle_clr="sc_layouts_item_icon_light" />

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <b className="bold-text">CONTACT US</b>
            <p><b className="new-text">HAVE QUESTIONS? GET IN TOUCH!</b></p>
            <p>
              <i className="fas fa-envelope email-icon"></i>
              <span>
                  <a href="mailto:coboxverse@cobox.co" className="email-link underline_hover">
                      coboxverse@cobox.co
                  </a>
              </span>
            </p>
          </div>
          
          <div className="col-md-6 form-container">
            <form>
              <div className="form-group">
                <div className="input-wrapper">
                  <i className="fas fa-user form-control-icon"></i>
                  <input type="text" placeholder="Name" className="input-line" required />
                </div>
          
                {/* <div className="input-wrapper">
                  <i className="fas fa-envelope form-control-icon"></i>
                  <input type="email" placeholder="Email Address" className="input-line" required />
                </div> */}
              </div>
              
              <div className="form-group">
                <div className="input-wrapper">
                  <i className="fab fa-telegram form-control-icon"></i>
                  <input type="text" placeholder="Telegram ID" className="input-line telegram" required />
                </div>
              </div>
          
              <button type="submit" className="btn btn-custom btn-block">
                <i className="fas fa-paper-plane"></i> <b>GET IN TOUCH</b>
              </button>
            
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
