import React from "react";
import './About.css';
import IndexNavbar from './IndexNavbar'
import Footer from './Footer'

export default function About() {
  return (
    <div class="non_index">
    <IndexNavbar logo="logo_clr.png" btn_class="color_style_light" toggle_clr="sc_layouts_item_icon_light" />
    <div class="container-fluid">
        <div class="row content">
          <div class="col-lg-7 col-md-12">
            <img src="/about2.jpg" alt="About Us Image" class="aboutUs-img" />
          </div>
          <div class="col-lg-5 col-md-12 about-text d-flex align-items-center">
            <div>
              <p><b>ABOUT US</b></p>
              <h1><b>WELCOME TO THE COBOX UNIVERSE</b></h1>
              <h5>CRAFT YOUR COSMOS</h5>
              <p>
                At Cobox, we redefine the boundaries of digital creation and interaction by merging imagination and innovation. Our pioneering virtual cosmos consists of interconnected planets with unique characteristics and themes, providing creators and businesses the unparalleled opportunity to sculpt their distinct metaverse landscapes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid section-padding">
        <div class="row">
          <div class="col-lg-8 col-md-12 text-lg-start text-center">
            <h1 class="our"><b>OUR MISSION</b></h1>
            <p class="our-text">
              At Cobox, we redefine the boundaries of digital creation and interaction by merging imagination and innovation. Our pioneering virtual cosmos consists of interconnected planets with unique characteristics and themes, providing creators and businesses the unparalleled opportunity to sculpt their distinct metaverse landscapes.
            </p> 
             <h1 class="who"><b>WHO WE ARE</b></h1>
            <p class="who-text">
              Cobox is powered by a diverse virtual team of scientists, researchers, architects, educators, creators, developers, gamers, and social media influencers. United by a shared vision, we delve into the fabric of the Cobox Universe, constantly exploring uncharted territories and unveiling new platforms that redefine virtual interaction and creation.
            </p>
          </div>
          <div class="col-lg-4 d-none d-lg-block">
            <img src="/about1.jpg" alt="Our mission image" />
          </div>
        </div>
      </div>


      <div class="container section-padding">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img src="/about3.jpg" alt="About Us Image" class="AboutUs" />
          </div>

          <div class="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            <div class="text-center">
              <h2 class="newtext"><b>OUR PLATFORM</b></h2>
              <p class="newtext2">
                ● <b>Cobox Studio:</b> A state-of-the-art no-code tool with a drag-and-drop interface, making the creation of high-quality games and immersive virtual environments accessible to everyone.<br />
                ● <b>Cobox Land:</b> The virtual real estate upon which users build their dreams, offering complete ownership and customization of virtual spaces.<br />
                ● <b>Cobox Token:</b> The primary currency within our ecosystem, facilitating transactions, rewarding participation, and encouraging creativity.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="section-padding text-center">
        <h1 class="join"><b>JOIN US</b></h1>
        <p class="join-text">
          In the Cobox Universe, we believe in the power of collaboration and the potential of every individual to contribute to the growth and diversity of our virtual worlds. Through our collective efforts, we are expanding the horizons of what is possible in the digital realm, creating a platform where creativity knows no bounds.
        </p>
      </div>
    
    <Footer />
    </div>
  );
}
