import { BrowserProvider } from "ethers"; // Correct import for BrowserProvider
import Web3Modal from "web3modal";

// Store the Web3Modal instance and provider globally
let web3Modal = null;
let provider = null;
let browserProvider = null;
let signer = null;
let address = null;

export const connectWallet = async () => {
  try {
    if (!web3Modal) {
      web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: {}, // Add wallet provider options if needed
      });
    }
    console.log("Attempting to connect to wallet...");

    // timeout for the connection attempt
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Connection timeout")), 10000) // Timeout after 10 seconds
    );

    // Attempt to connect with a timeout mechanism
    const connectPromise = web3Modal.connect();
    provider = await Promise.race([connectPromise, timeout]);

    browserProvider = new BrowserProvider(provider);
    signer = await browserProvider.getSigner();
    address = await signer.getAddress();

    console.log("Connected Address:", address);
    return { provider, browserProvider, signer, address };
  } catch (error) {
    console.error("Error connecting to wallet:", error);
    return 0; // Return 0 when connection fails or times out
  }
};


export const disconnectWallet = () => {
  if (provider?.disconnect) {
    provider.disconnect();
    console.log("Disconnected from wallet");
  }
  
  // Reset the global variables
  provider = null;
  browserProvider = null;
  signer = null;
  address = null;
};
